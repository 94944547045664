import { auth, db } from "@lib/firebase-internal/clientApp";
import { collection, getDocs } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import React from "react";

const whitelistedCampaigns =
    process.env.NODE_ENV === "production"
        ? [
              "adva-soft-batched-a-multi-photo-editing-app-",
              "bookbeat-audioboeken-makkelijk-en-betaalbaar-nl",
              "bookbeat-audiobooks-made-easy-and-affordable",
              "bookbeat-audiobooks-made-easy-and-affordable-it",
              "bookbeat-audiobooks-made-easy-and-affordable-norway",
              "bookbeat-bookbeat-audiolibros-f-ciles-y-asequibles-es",
              "bookbeat-bookbeat-odkryj-wiat-audiobook-w-atwiej-i-taniej-ni-kiedykolwiek-pl",
              "bookbeat-des-livres-audio-faciles-lire-et-abordables",
              "doubble-doubble-app-meet-new-people-with-your-people",
              "dr-smile-dr-smile-denti-dritti-con-allineatori-invisibili",
              "dr-smile-dr-smile-es-dientes-perfectamente-alineados-gracias-a-los-alineadores-invisibles",
              "factor-factor-gezond-eten-gemakkelijk-gemaakt-belgium",
              "filify-become-ambassador-for-filify-poland",
              "greenmobility-duurzame-stedelijke-mobiliteit-gemakkelijk-gemaakt",
              "greenmobility-sustainable-urban-shared-mobility-made-easy-be",
              "growth-bundle-achieve-your-goals-with-one-app-collection",
              "growth-bundle-growth-bundle",
              "hellofresh-bo-tes-repas-domicile-france",
              "hellofresh-factor-healthy-eating-made-easy-nederland",
              "hellofresh-green-chef-meal-boxes-right-to-your-door-uk",
              "hellofresh-hellofresh-box-di-ricette-direttamente-a-casa-tua-italia",
              "hellofresh-hellofresh-meal-boxes-right-to-your-door-austria",
              "hellofresh-hellofresh-meal-boxes-right-to-your-door-germany",
              "hellofresh-hellofresh-meal-boxes-right-to-your-door-switzerland",
              "hellofresh-hellofresh-meal-boxes-right-to-your-door-united-kingdom",
              "hellofresh-hellofresh-recetas-e-ingredientes-deliciosos-a-domicilio-espa-a-",
              "hellofresh-maaltijdboxen-gelevered-aan-huis-nederland",
              "hellofresh-meal-boxes-right-to-your-door-",
              "hellofresh-meal-boxes-right-to-your-door-be",
              "hellofresh-meal-boxes-right-to-your-door-belgium",
              "hellofresh-meal-boxes-right-to-your-door-ireland",
              "hellofresh-meal-boxes-right-to-your-door-netherlands",
              "hellofresh-meal-boxes-right-to-your-door-sweden",
              "marley-spoon-marley-spoon-delicious-cooking-made-easy-netherlands",
              "marley-spoon-marley-spoon-leckeres-kochen-leicht-gemacht-deutschland",
              "podimo-hundreds-of-exclusive-podcasts-and-audiobooks-germany",
              "podimo-hundreds-of-exclusive-podcasts-and-audiobooks-norway",
              "podimo-hundreds-of-exclusive-podcasts-and-audiobooks-spain",
              "podimo-podimo-cientos-de-podcasts-y-audiolibros-exclusivos-m-xico",
              "podimo-podimo-hundreds-of-exclusive-podcasts-and-audiobooks-netherlands",
              "podimo-the-best-exclusive-podcasts-and-thousands-of-audiobooks-together",
              "podme-podme-se-platsen-f-r-dina-favoritpoddar",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-finland",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-france",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-italy",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-netherlands",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-norway",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-poland",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-spain",
              "stepler-stepler-app-get-rewarded-for-every-step-you-take-sweden",
              "storytel-a-universe-of-e-books-and-audiobooks-belgium",
              "storytel-a-universe-of-e-books-and-audiobooks-netherlands",
              "storytel-a-universe-of-e-books-and-audiobooks-norway",
              "storytel-a-universe-of-e-books-and-audiobooks-sweden",
              "wolt-naj-atwiejszy-spos-b-na-dostarczenie-pysznego-posi-ku-prosto-do-twojego-domu",
          ]
        : [
              "goodiebox-goodiebox-instagram-dk-fork-l-dine-f-lgere-med-et-velkomst-produkt",
          ];

const useHasCampaignWithAutoPayout = () => {
    const [hasCampaignWithAutoPayout, setHasCampaignWithAutoPayout] =
        React.useState(false);
    const [user, userLoading] = useAuthState(auth);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (!userLoading && !user) {
            setLoading(false);
            return;
        }

        if (!user) {
            return;
        }

        const campaignRequestRef = collection(
            db,
            "users",
            user.uid,
            "requestedCampaigns",
        );

        (async () => {
            setLoading(true);

            const campaignRequestsSnapshot = await getDocs(campaignRequestRef);
            const hasWhitelistedCampaign = campaignRequestsSnapshot.docs.some(
                doc => whitelistedCampaigns.includes(doc.id),
            );

            if (hasWhitelistedCampaign) {
                setHasCampaignWithAutoPayout(true);
            }

            setLoading(false);
        })().catch(() => setLoading(false));
    }, [user, userLoading]);

    return [hasCampaignWithAutoPayout, loading];
};

export default useHasCampaignWithAutoPayout;
